import { BalancingItem, BalancingViewMode, IProps } from '../BalancingItem';
import { Box, Typography } from '@mui/material';

import clsx from 'clsx';
import { formatBalance } from '../../utils';
import useStyles from './styles';

export const BalancingItemWithTabs = ({
  project,
  accounts,
  balanceAdjustments,
  item,
  mode,
  onChangeMode,
  onAdjustmentsChanged,
}: IProps) => {
  const { classes } = useStyles();

  return (
    <Box className={clsx([classes.root, mode === BalancingViewMode.accounts ? 'rp-0' : 'lp-0'])}>
      <Box
        className={clsx([
          classes.tab,
          'left',
          'blue',
          mode === BalancingViewMode.accounts && 'active',
        ])}
        onClick={() => onChangeMode(BalancingViewMode.accounts)}
      >
        <Typography variant='subtitle1'>
          {formatBalance(item.sourceBalance + item.adjustments + item.sectionAdjustments)}
        </Typography>
      </Box>
      <Box
        className={clsx([
          classes.tab,
          'right',
          'green',
          mode === BalancingViewMode.balanceSheet && 'active',
        ])}
        onClick={() => onChangeMode(BalancingViewMode.balanceSheet)}
      >
        <Typography variant='subtitle1'>{formatBalance(item.totalBalance)}</Typography>
      </Box>
      <BalancingItem
        project={project}
        item={item}
        main
        accounts={accounts}
        balanceAdjustments={balanceAdjustments}
        mode={mode}
        onChangeMode={onChangeMode}
        onAdjustmentsChanged={onAdjustmentsChanged}
      />
    </Box>
  );
};
