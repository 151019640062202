import { AdjustmentOperation, OperationMap } from '../../common/AdjustmentOperation';
import { Box, Container, IconButton, Switch, Tooltip, Typography } from '@mui/material';
import { formatBalance, formatPercentage } from '../../utils';
import { getAdjustmentDefinition, getAdjustments } from '@services/api';
import { useApi, useConfirm, useLoader } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { AccountsDialog } from '../AccountsDialog';
import { Actions } from '@models/enums/Actions';
import AlertIcon from '@assets/icons/dashboard/alert-triangle-filled.svg';
import CheckIcon from '@assets/icons/dashboard/check-circle-filled.svg';
import EditIcon from '@assets/icons/dashboard/edit.svg';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { IAdjustmentDefinition } from '@models/interfaces/entities/IAdjustmentDefinition';
import { IProject } from '@models/interfaces/entities/IProject';
import { IUpdateAdjustmentDefinitionData } from '@models/interfaces/additional/IUpdateAdjustmentDefinitionData';
import { Loader } from '@components/Loader';
import MinusIcon from '@assets/icons/dashboard/minus.svg';
import PlusIcon from '@assets/icons/dashboard/plus-square-green.svg';
import TrashIcon from '@assets/icons/dashboard/trash.svg';
import XIcon from '@assets/icons/dashboard/x-circle-red.svg';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import useStyles from './styles';
import { v4 as uuidv4 } from 'uuid';

export interface IAdjustmentDetails {
  adjustmentDefinitionId: string;
  sourceSubAccountId?: string | null;
  sourceSubAccountDescription?: string | null;
  operation: string;
  sourceTotal: number;
  matchingBalance: number;
  sourceAdjustment: number;
  groupId?: string | null;
  adjustments: IAdjustment[];
  reverseBalance: boolean;
  groupSources: {
    reverseBalance: boolean;
    sourceSubAccountId?: string | null;
    sourceTotal: number;
  }[];
}

interface IProps {
  details: IAdjustmentDetails;
  accounts: IAccount[];
  category: string;
  project: IProject;
  types: number[];
  updateAdjustmentDefinition?: (
    url: string,
    data: IUpdateAdjustmentDefinitionData,
    callback?: () => void,
  ) => void;
  deleteAdjustmentDefinition?: (url: string) => void;
}

export const AdjustmentDetails = ({
  details,
  accounts,
  category,
  project,
  types,
  updateAdjustmentDefinition,
  deleteAdjustmentDefinition,
}: IProps) => {
  const { classes } = useStyles();
  const confirm = useConfirm();

  const [adjustmentDefinition, setAdjustmentDefinition] = useState<IAdjustmentDefinition>();
  const [detailsAdjustments, setDetailsAdjustments] = useState<IAdjustment[]>([]);
  const [balanceAdjustments, setBalanceAdjustments] = useState<IAdjustment[]>([]);
  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const [targetAccountIds, setTargetAccountIds] = useState<string[]>([]);
  const [reverseBalance, setReverseBalance] = useState<boolean>(details.reverseBalance);
  const [sourceAdjustment, setSourceAdjustment] = useState<number>(details.sourceAdjustment);
  const [storedDetailsAdjustments, setStoredDetailsAdjustments] = useState<IAdjustment[]>([]);
  const [storedBalanceAdjustments, setStoredBalanceAdjustments] = useState<IAdjustment[]>([]);
  const [openAccountsDialog, setOpenAccountsDialog] = useState(false);

  const balanceOperations = useMemo(() => {
    if (
      details.operation === AdjustmentOperation.Add ||
      details.operation === AdjustmentOperation.Subtract ||
      details.operation === AdjustmentOperation.Match
    ) {
      return [AdjustmentOperation.Initial, AdjustmentOperation.Manual];
    } else if (details.operation === AdjustmentOperation.Fees) {
      return [
        AdjustmentOperation.Initial,
        AdjustmentOperation.Manual,
        AdjustmentOperation.Add,
        AdjustmentOperation.Subtract,
        AdjustmentOperation.Match,
      ];
    }
    return [];
  }, [details.operation]);

  const { request: getAdjustmentDefinitionRequest, loading: getAdjustmentDefinitionLoading } =
    useApi(getAdjustmentDefinition, null, {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          setAdjustmentDefinition(data);
          setTargetAccountIds(data.targets.map((x) => x.id));
        }
      },
    });

  const { request: getAdjustmentsRequest, loading: getAdjustmentsLoading } = useApi(
    getAdjustments,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          const balanceAdjustments = data.items.filter((x) =>
            balanceOperations.includes(x.operation as AdjustmentOperation),
          );
          setBalanceAdjustments(balanceAdjustments);

          const detailsAdjustments = data.items.filter(
            (x) => x.adjustmentDefinitionId === details.adjustmentDefinitionId,
          );
          setDetailsAdjustments(detailsAdjustments);
        }
      },
    },
  );

  const { request: reloadBalanceAdjustmentsRequest, loading: reloadBalanceAdjustmentsLoading } =
    useApi(getAdjustments, null, {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          const balanceAdjustments = data.items.filter((x) =>
            balanceOperations.includes(x.operation as AdjustmentOperation),
          );
          setBalanceAdjustments(balanceAdjustments);
        }
      },
    });

  const getAccountTotalBalance = useCallback(
    (accountId: string, operations: string[], adjustments: IAdjustment[]) => {
      return adjustments
        .filter((x) => x.accountId === accountId && operations.includes(x.operation))
        .reduce((p, c) => p + c.amount, 0);
    },
    [],
  );

  const targetAccountBalances = useMemo(() => {
    return targetAccountIds.map((x) => ({
      accountId: x,
      initialBalance:
        details.operation === AdjustmentOperation.Match
          ? getAccountTotalBalance(x, [AdjustmentOperation.Initial], balanceAdjustments)
          : 0,
      balance: getAccountTotalBalance(x, balanceOperations, balanceAdjustments),
    }));
  }, [targetAccountIds, balanceAdjustments, details.operation, balanceOperations]);

  const totalTargetAccountsBalance = useMemo(
    () => targetAccountBalances.reduce((prev, acc) => prev + acc.balance, 0),
    [targetAccountBalances],
  );

  const detailAdjustmentItems = useMemo(() => {
    const baselineAccountIds =
      details.operation === AdjustmentOperation.Match && adjustmentDefinition
        ? adjustmentDefinition.baseline.map((x) => x.id)
        : [];

    const baselineOnlyAccountIds =
      details.operation === AdjustmentOperation.Match && adjustmentDefinition
        ? baselineAccountIds.filter(
            (x) => adjustmentDefinition.targets.findIndex((a) => a.id === x) === -1,
          )
        : [];

    const targetAccountsData = detailsAdjustments.map((x) => {
      const account = accounts.find((a) => a.id === x.accountId);
      const targetAccountBalance = targetAccountBalances.find(
        (tab) => tab.accountId === x.accountId,
      );
      return {
        account,
        initialBalance: targetAccountBalance?.initialBalance || 0,
        balance: targetAccountBalance?.balance || 0,
        adjustment: !editModeEnabled
          ? x.amount
          : (totalTargetAccountsBalance !== 0
              ? (targetAccountBalance?.balance || 0) / totalTargetAccountsBalance
              : 1 / targetAccountBalances.length) * sourceAdjustment,
        adjusted: true,
        isBaseline: baselineAccountIds.includes(x.accountId),
        hasCorrectType:
          account &&
          types.includes(account.accountType.type) &&
          !account.isBalancing &&
          account.summaryCode !== 1,
      };
    });

    const baselineAccountsData = baselineOnlyAccountIds.map((x) => {
      const account = accounts.find((a) => a.id === x);
      return {
        account,
        initialBalance: getAccountTotalBalance(
          x,
          [AdjustmentOperation.Initial],
          balanceAdjustments,
        ),
        balance: 0,
        adjustment: 0,
        adjusted: false,
        isBaseline: true,
        hasCorrectType:
          account &&
          types.includes(account.accountType.type) &&
          !account.isBalancing &&
          account.summaryCode !== 1,
      };
    });

    return [...targetAccountsData, ...baselineAccountsData].sort((a, b) =>
      (a.account?.accountName || 'Unknown').localeCompare(b.account?.accountName || 'Unknown'),
    );
  }, [
    detailsAdjustments,
    accounts,
    types,
    balanceAdjustments,
    details.operation,
    details.adjustmentDefinitionId,
    sourceAdjustment,
    adjustmentDefinition,
    targetAccountBalances,
    totalTargetAccountsBalance,
    editModeEnabled,
  ]);

  const filteredAccounts = useMemo(
    () =>
      accounts.filter(
        (x) => types.includes(x.accountType.type) && x.summaryCode !== 1 && !x.isBalancing,
      ),
    [accounts, types],
  );

  const calculateSourceAdjustment = useCallback(
    (operation: string, sourceTotal: number, reverseBalance: boolean) => {
      if (operation === AdjustmentOperation.Add || operation === AdjustmentOperation.Fees) {
        return sourceTotal * (reverseBalance ? -1 : 1);
      } else if (operation === AdjustmentOperation.Subtract) {
        return sourceTotal * (reverseBalance ? 1 : -1);
      } else {
        return 0;
      }
    },
    [],
  );

  const onChangeReverseBalance = (value: boolean) => {
    setReverseBalance(value);
    setSourceAdjustment(calculateSourceAdjustment(details.operation, details.sourceTotal, value));
  };

  const onSave = () => {
    if (!targetAccountIds.length) {
      toast.error('At least one target account should be specified');
      return;
    }
    const [firstAdjustment] = detailsAdjustments;
    if (
      updateAdjustmentDefinition !== undefined &&
      !!firstAdjustment?.links[Actions.updateDefinition] &&
      adjustmentDefinition
    ) {
      updateAdjustmentDefinition(
        firstAdjustment.links[Actions.updateDefinition].href,
        {
          reverseBalance: details.reverseBalance !== reverseBalance ? reverseBalance : undefined,
          source: {
            type: adjustmentDefinition.source.type,
            id: adjustmentDefinition.source.value.id,
          },
          targets: targetAccountIds,
          operation: adjustmentDefinition.operation,
          baseline: [],
          tierLimits: [],
        },
        () => {
          if (project.links[Actions.getAdjustmentDefinitions]?.href)
            getAdjustmentDefinitionRequest(
              `${project.links[Actions.getAdjustmentDefinitions].href}/${
                details.adjustmentDefinitionId
              }`,
            );
          setEditModeEnabled(false);
        },
      );
    }
  };

  const onDiscard = () => {
    setTargetAccountIds(adjustmentDefinition?.targets.map((x) => x.id) || []);
    setReverseBalance(details.reverseBalance);
    setSourceAdjustment(details.sourceAdjustment);
    setDetailsAdjustments(storedDetailsAdjustments.slice());
    setBalanceAdjustments(storedBalanceAdjustments.slice());
    setEditModeEnabled(false);
  };

  const onEdit = () => {
    setTargetAccountIds(adjustmentDefinition?.targets.map((x) => x.id) || []);
    setReverseBalance(details.reverseBalance);
    setSourceAdjustment(
      calculateSourceAdjustment(details.operation, details.sourceTotal, details.reverseBalance),
    );
    setStoredDetailsAdjustments(detailsAdjustments.slice());
    setStoredBalanceAdjustments(balanceAdjustments.slice());
    setEditModeEnabled(true);
  };

  const onDelete = async () => {
    const [firstAdjustment] = detailsAdjustments;
    if (
      deleteAdjustmentDefinition !== undefined &&
      !!firstAdjustment?.links[Actions.deleteDefinition]
    ) {
      await confirm({
        title: 'Delete adjustment',
        description: 'Are you sure you wish to delete this adjustment?',
        confirmationText: 'Confirm',
      });

      deleteAdjustmentDefinition(firstAdjustment.links[Actions.deleteDefinition].href);
    }
  };

  const onRemoveAccount = (accountId: string) => {
    setTargetAccountIds((prev) => prev.filter((x) => x !== accountId));
    setDetailsAdjustments((prev) => prev.filter((x) => x.accountId !== accountId));
  };

  const onAddAccount = () => {
    setOpenAccountsDialog(true);
  };

  const onCloseAccountsDialog = (selectedAccountsIds: string[]) => {
    if (project.links[Actions.getAdjustments]?.href) {
      reloadBalanceAdjustmentsRequest(
        project.links[Actions.getAdjustments].href,
        balanceOperations,
        selectedAccountsIds,
      );
    }
    setTargetAccountIds(selectedAccountsIds.slice());
    setDetailsAdjustments((prev) => {
      const filteredAdjustments = prev.filter((x) => selectedAccountsIds.includes(x.accountId));
      const accountsIdsWithoutAdjustment = selectedAccountsIds.filter(
        (x) => prev.findIndex((a) => a.accountId === x) === -1,
      );
      return [
        ...filteredAdjustments,
        ...accountsIdsWithoutAdjustment.map(
          (x) =>
            ({
              accountId: x,
              operation: details.operation,
              id: uuidv4(),
              adjustmentDefinitionId: details.adjustmentDefinitionId,
              reverseBalance: details.reverseBalance,
              amount: 0,
              sourceAdjustment: sourceAdjustment,
              sourceTotal: details.sourceTotal,
              links: {},
            } as IAdjustment),
        ),
      ];
    });

    setOpenAccountsDialog(false);
  };

  useEffect(() => {
    if (project.links[Actions.getAdjustmentDefinitions]?.href)
      getAdjustmentDefinitionRequest(
        `${project.links[Actions.getAdjustmentDefinitions].href}/${details.adjustmentDefinitionId}`,
      );
  }, [project.links[Actions.getAdjustmentDefinitions]?.href, details.adjustmentDefinitionId]);

  useEffect(() => {
    if (adjustmentDefinition) {
      const usedAccountIds = Array.from(
        new Set([
          ...adjustmentDefinition.targets.map((x) => x.id),
          ...adjustmentDefinition.baseline.map((x) => x.id),
        ]),
      );

      if (project.links[Actions.getAdjustments]?.href) {
        getAdjustmentsRequest(
          project.links[Actions.getAdjustments].href,
          [...balanceOperations, details.operation],
          usedAccountIds,
        );
      }
    }
  }, [
    filteredAccounts,
    adjustmentDefinition,
    balanceOperations,
    project.links[Actions.getAdjustments]?.href,
  ]);

  const detailAdjustmentsTotalAdjustment = useMemo(
    () => detailAdjustmentItems.reduce((p, c) => p + c.adjustment, 0),
    [detailAdjustmentItems],
  );

  const detailAdjustmentsTotalAccountsBalance = useMemo(
    () => detailAdjustmentItems.reduce((p, c) => p + c.balance, 0),
    [detailAdjustmentItems],
  );

  const detailAdjustmentsTotalAccountsInitialBalance = useMemo(
    () => detailAdjustmentItems.reduce((p, c) => p + c.initialBalance, 0),
    [detailAdjustmentItems],
  );

  const isMatch = useMemo(
    () => details.operation === AdjustmentOperation.Match,
    [details.operation],
  );

  const titleClass = useMemo(() => (isMatch ? classes.colSpan5 : classes.colSpan4), [isMatch]);
  const adjustmentColumnPositionClass = useMemo(
    () => (isMatch ? classes.col6 : classes.col5),
    [isMatch],
  );
  const balanceColumnPositionClass = useMemo(
    () => (isMatch ? classes.col4 : classes.col3),
    [isMatch],
  );
  const spacerClass = useMemo(() => (isMatch ? classes.col6Spacer : classes.col5Spacer), [isMatch]);
  const gridClass = useMemo(() => (isMatch ? classes.col6Grid : classes.col5Grid), [isMatch]);

  const showLoader = useLoader(
    getAdjustmentDefinitionLoading,
    getAdjustmentsLoading,
    reloadBalanceAdjustmentsLoading,
  );

  const sourceTotalSum = useMemo(
    () =>
      isMatch
        ? details.groupSources.reduce(
            (sum, source) => sum + source.sourceTotal * (source.reverseBalance ? -1 : 1),
            0,
          )
        : 0,
    [isMatch, details.groupSources],
  );

  const sourceTotalPortion = useMemo(
    () => (isMatch ? details.sourceTotal / sourceTotalSum : 1),
    [isMatch, sourceTotalSum, details.sourceTotal],
  );

  const deleteEnabled = useMemo(() => {
    const [firstAdjustment] = detailsAdjustments;
    return (
      deleteAdjustmentDefinition !== undefined && !!firstAdjustment?.links[Actions.deleteDefinition]
    );
  }, [deleteAdjustmentDefinition, detailsAdjustments]);

  const updateEnabled = useMemo(() => {
    const [firstAdjustment] = detailsAdjustments;
    return (
      updateAdjustmentDefinition !== undefined &&
      !!firstAdjustment?.links[Actions.updateDefinition] &&
      (details.operation === AdjustmentOperation.Add ||
        details.operation === AdjustmentOperation.Subtract ||
        details.operation === AdjustmentOperation.Fees)
    );
  }, [updateAdjustmentDefinition, detailsAdjustments, details.operation]);

  return (
    <>
      <Box className={classes.root}>
        <Container maxWidth='md'>
          {details.operation === AdjustmentOperation.Add ||
          details.operation === AdjustmentOperation.Subtract ||
          details.operation === AdjustmentOperation.Fees ||
          details.operation === AdjustmentOperation.Match ? (
            <Box className={clsx([classes.grid, gridClass])}>
              <Box className={clsx([classes.header, titleClass, classes.mh26])}>
                <Box>{details.sourceSubAccountId}</Box>
                {!editModeEnabled && updateEnabled && (
                  <Tooltip title='Edit adjustment'>
                    <IconButton onClick={onEdit} size='small'>
                      <img src={EditIcon} alt='edit' />
                    </IconButton>
                  </Tooltip>
                )}
                {!editModeEnabled && deleteEnabled && (
                  <Tooltip title='Delete adjustment'>
                    <IconButton onClick={onDelete} size='small'>
                      <img src={TrashIcon} alt='trash' />
                    </IconButton>
                  </Tooltip>
                )}
                {editModeEnabled && (
                  <Tooltip title='Accept changes'>
                    <IconButton onClick={onSave} size='small'>
                      <img src={CheckIcon} alt='check' />
                    </IconButton>
                  </Tooltip>
                )}
                {editModeEnabled && (
                  <Tooltip title='Discard changes'>
                    <IconButton onClick={onDiscard} size='small'>
                      <img src={XIcon} alt='x' />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              <Box className={clsx([classes.header, classes.textRight])}>
                {OperationMap[details.operation]}
              </Box>
              <Box className={spacerClass} />

              {isMatch ? (
                <>
                  <Box className={clsx([classes.header, 'alignItemsEnd'])}>
                    Balance Sheet Line Items
                  </Box>
                  <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
                    Balance
                  </Box>
                  <Box className={clsx([classes.header, classes.textCenter, 'alignItemsEnd'])}>
                    Reverse Balance
                  </Box>
                  <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
                    Portion of Balance
                  </Box>

                  {details.groupSources.map((source) => (
                    <>
                      <Box className={clsx([classes.pl30, classes.col1])}>
                        {source.sourceSubAccountId || 'Unknown'}
                      </Box>
                      <Box className={classes.textRight}>{formatBalance(source.sourceTotal)}</Box>
                      <Box className={clsx([classes.textCenter])}>
                        <Switch
                          inputProps={{ role: 'switch' }}
                          checked={source.reverseBalance}
                          size='small'
                          disabled
                        />
                      </Box>
                      {details.sourceSubAccountId === source.sourceSubAccountId && (
                        <Box className={classes.textRight}>
                          {formatPercentage(sourceTotalPortion)}
                        </Box>
                      )}
                    </>
                  ))}

                  <Box className={clsx([classes.header, classes.pl30, classes.col1])}>Total</Box>
                  <Box className={clsx([classes.header, classes.textRight])}>
                    {formatBalance(sourceTotalSum)}
                  </Box>
                </>
              ) : (
                <>
                  <Box className={clsx([classes.header, 'alignItemsEnd'])}>Line Item Balance</Box>
                  <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
                    {formatBalance(details.sourceTotal * (details.reverseBalance ? -1 : 1))}
                  </Box>
                  <Box className={clsx([classes.header, 'col', 'alignItemsEnd'])}>
                    Reverse Balance <br />
                    <Switch
                      inputProps={{ role: 'switch' }}
                      checked={reverseBalance}
                      size='small'
                      onChange={(_, val) => onChangeReverseBalance(val)}
                      disabled={!editModeEnabled}
                    />
                  </Box>
                </>
              )}
              <Box className={spacerClass} />

              <Box className={clsx([classes.header, 'alignItemsEnd'])}>
                {isMatch ? (
                  'Match Balance To'
                ) : (
                  <>
                    Apply Adjustment To
                    {editModeEnabled && (
                      <Tooltip title='Add account'>
                        <IconButton onClick={onAddAccount} size='small'>
                          <img src={PlusIcon} alt='+' />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                )}
              </Box>
              {isMatch && (
                <>
                  <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
                    Source Balance
                  </Box>
                  <Box className={clsx([classes.header, classes.textCenter, 'alignItemsEnd'])}>
                    Adjust
                  </Box>
                </>
              )}
              <Box
                className={clsx([
                  classes.header,
                  balanceColumnPositionClass,
                  classes.textRight,
                  'alignItemsEnd',
                ])}
              >
                {isMatch ? 'Manually ' : ''}Adjusted Balance
              </Box>
              <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
                Portion of Total
              </Box>
              <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
                Adjustment
              </Box>

              {detailAdjustmentItems.map((x) => (
                <>
                  <Box
                    className={clsx([
                      !editModeEnabled && classes.pl30,
                      classes.col1,
                      'gap4',
                      classes.mh26,
                      (!x.hasCorrectType || (!x.isBaseline && isMatch)) && classes.red,
                    ])}
                    key={x.account?.id}
                  >
                    {editModeEnabled && (
                      <Tooltip title='Remove account'>
                        <IconButton
                          onClick={() => x.account && onRemoveAccount(x.account.id)}
                          size='small'
                        >
                          <img src={MinusIcon} alt='-' />
                        </IconButton>
                      </Tooltip>
                    )}
                    {x.isBaseline || !isMatch ? (
                      x.hasCorrectType ? (
                        x.account?.accountName || 'Unknown'
                      ) : (
                        <Tooltip
                          title={`This account is not of the correct type (in this case, ${category}). Adjustments defined within a particular account category should only target accounts of the corresponding category.`}
                        >
                          <Box className={clsx([classes.flex, classes.red])}>
                            <img src={AlertIcon} alt='alert' />
                            {x.account?.accountName || 'Unknown'}
                          </Box>
                        </Tooltip>
                      )
                    ) : (
                      <Tooltip
                        title={
                          <Box>
                            This account is not included in the baseline adjustment calculation. To
                            remedy this, either:
                            <ul>
                              <li>Add this account to the baseline adjustment calculation, or</li>
                              <li>Remove this account as an adjustment target.</li>
                            </ul>
                          </Box>
                        }
                      >
                        <Box className={clsx([classes.flex, classes.red])}>
                          <img src={AlertIcon} alt='alert' />
                          {x.account?.accountName || 'Unknown'}
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                  {isMatch && (
                    <>
                      <Box
                        className={clsx([
                          classes.textRight,
                          (!x.hasCorrectType || (!x.isBaseline && isMatch)) && classes.red,
                        ])}
                      >
                        {formatBalance(x.isBaseline ? x.initialBalance : 0)}
                      </Box>
                      <Box className={clsx([classes.textCenter])}>
                        <Switch
                          inputProps={{ role: 'switch' }}
                          checked={x.adjusted}
                          size='small'
                          disabled
                        />
                      </Box>
                    </>
                  )}
                  {x.adjusted && (
                    <Box
                      className={clsx([
                        balanceColumnPositionClass,
                        classes.textRight,
                        (!x.hasCorrectType || (!x.isBaseline && isMatch)) && classes.red,
                      ])}
                    >
                      {formatBalance(x.balance)}
                    </Box>
                  )}
                  {x.adjusted && (
                    <Box
                      className={clsx([
                        classes.textRight,
                        (!x.hasCorrectType || (!x.isBaseline && isMatch)) && classes.red,
                      ])}
                    >
                      {formatPercentage(
                        detailAdjustmentsTotalAccountsBalance
                          ? x.balance / detailAdjustmentsTotalAccountsBalance
                          : 1 / detailAdjustmentItems.length,
                      )}
                    </Box>
                  )}
                  {x.adjusted && (
                    <Box className={clsx([classes.green, classes.textRight])}>
                      {formatBalance(x.adjustment)}
                    </Box>
                  )}
                </>
              ))}

              <Box className={clsx([classes.pl30, classes.header, classes.col1])}>Total</Box>
              <Box
                className={clsx([classes.header, balanceColumnPositionClass, classes.textRight])}
              >
                {formatBalance(detailAdjustmentsTotalAccountsBalance)}
              </Box>
              <Box className={clsx([classes.header, classes.textRight])}>{formatPercentage(1)}</Box>
              <Box className={spacerClass} />
              {isMatch ? (
                <>
                  <Box className={classes.header}>Total Adjustment</Box>
                  <Box className={clsx([classes.header, classes.textRight])}>
                    {formatBalance(sourceTotalSum - detailAdjustmentsTotalAccountsInitialBalance)}
                  </Box>
                  <Box className={clsx([classes.header, classes.col1])}>Line Item Portion</Box>
                  <Box className={classes.textRight}>{formatPercentage(sourceTotalPortion)}</Box>

                  <Box className={clsx([classes.header, classes.col1])}>Line Item Adjustment</Box>
                  <Box className={clsx([classes.header, classes.textRight])}>
                    {formatBalance(sourceAdjustment)}
                  </Box>
                  <Box
                    className={clsx([
                      classes.header,
                      classes.green,
                      adjustmentColumnPositionClass,
                      classes.textRight,
                    ])}
                  >
                    {formatBalance(detailAdjustmentsTotalAdjustment)}
                  </Box>
                </>
              ) : (
                <>
                  <Box className={classes.header}>Total Adjustment</Box>
                  <Box className={clsx([classes.header, classes.textRight])}>
                    {formatBalance(sourceAdjustment)}
                  </Box>
                  <Box
                    className={clsx([
                      classes.header,
                      classes.green,
                      adjustmentColumnPositionClass,
                      classes.textRight,
                    ])}
                  >
                    {formatBalance(detailAdjustmentsTotalAdjustment)}
                  </Box>
                </>
              )}
            </Box>
          ) : (
            <Typography variant='h6'>Not implemented yet</Typography>
          )}
        </Container>
      </Box>
      <AccountsDialog
        accounts={accounts}
        selectedAccountIds={targetAccountIds}
        title='Select Target Accounts'
        subtitle={details.sourceSubAccountId || ''}
        category={category}
        onClose={onCloseAccountsDialog}
        open={openAccountsDialog}
      />
      <Loader show={showLoader} />
    </>
  );
};
