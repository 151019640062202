import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  pt24: {
    paddingTop: 24,
  },
  expandButton: {
    width: 100,
    backgroundColor: t.palette.custom.info[50],
    paddingTop: 4,
    paddingBottom: 4,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover, &:active': {
      backgroundColor: t.palette.custom.info[70],
    },
  },
}));

export default useStyles;
