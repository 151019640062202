import { Box, Collapse, IconButton, Tooltip, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

import { AccountsTable } from '../AccountsTable';
import AlertIcon from '@assets/icons/dashboard/alert-triangle-filled.svg';
import { BalanceSheetTable } from '../BalanceSheetTable';
import ChevronDownIcon from '@assets/icons/dashboard/chevron-down-gray.svg';
import ChevronUpIcon from '@assets/icons/dashboard/chevron-up-gray.svg';
import { CollapsibleAdjustmentsDetails } from '../CollapsibleAdjustmentsDetails';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { IProject } from '@models/interfaces/entities/IProject';
import clsx from 'clsx';
import { formatBalance } from '../../utils';
import useStyles from './styles';

export enum BalancingViewMode {
  accounts = 'accounts',
  balanceSheet = 'balanceSheet',
}

export interface IBalancingItem {
  title: string;
  accountsType: number;
  balancingType: number;
  balancingAccountId?: string;
  sourceBalance: number;
  adjustments: number;
  sectionAdjustments: number;
  ownedSectionAdjustment: number;
  receivedSectionAdjustment: number;
  totalBalance: number;
  hideSource?: boolean;
  showFees?: boolean;
  showPremiums?: boolean;
  items?: IBalancingItem[];
}

export interface IProps {
  project: IProject;
  accounts: IAccount[];
  balanceAdjustments: IAdjustment[];
  main?: boolean;
  item: IBalancingItem;
  mode: BalancingViewMode;
  onChangeMode: (mode: BalancingViewMode) => void;
  onAdjustmentsChanged: () => void;
}

export const BalancingItem = ({
  project,
  accounts,
  balanceAdjustments,
  main,
  item,
  mode,
  onChangeMode,
  onAdjustmentsChanged,
}: IProps) => {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState(false);

  const onToggleExpanded = () => {
    setExpanded((val) => !val);
  };

  const onBalanceClick = (mode: BalancingViewMode) => {
    setExpanded(true);
    onChangeMode(mode);
  };

  const isBalanced = useMemo(() => {
    if (!item.totalBalance || item.items?.length) {
      return true;
    }
    const accountsTotal =
      item.sourceBalance +
      item.adjustments +
      item.sectionAdjustments -
      item.receivedSectionAdjustment;

    return Math.abs(Math.round((item.totalBalance - accountsTotal) * 100) / 100) <= 1;
  }, [item]);

  const balanceRecords = [
    {
      label: 'Source Balance',
      amount: item.hideSource ? 'N/A' : formatBalance(item.sourceBalance),
      class: 'blue',
      mode: BalancingViewMode.accounts,
    },
    {
      label: 'Line Item Adjustments',
      amount: formatBalance(item.adjustments),
      class: 'blue',
      mode: BalancingViewMode.accounts,
    },
    {
      label: 'Section Adjustments',
      amount: formatBalance(item.sectionAdjustments),
      class: 'blue',
      mode: BalancingViewMode.accounts,
    },
    {
      label: 'Balance Sheet Total',
      amount: formatBalance(item.totalBalance),
      class: 'green',
      mode: BalancingViewMode.balanceSheet,
      alert: !isBalanced,
    },
  ];

  const getAllAccountsTypes = useCallback((item: IBalancingItem) => {
    const accountsTypes: number[] = [item.accountsType];

    const addChildAccountsTypes = (items?: IBalancingItem[]) => {
      if (!items) return;

      items.forEach((child) => {
        accountsTypes.push(child.accountsType);
        addChildAccountsTypes(child.items);
      });
    };

    addChildAccountsTypes(item.items);
    return accountsTypes;
  }, []);

  const allAccountsTypes = useMemo(() => getAllAccountsTypes(item), [item]);

  const filteredBalanceAdjustments = useMemo(() => {
    const filteredAccountsIds = accounts
      .filter((x) => allAccountsTypes.includes(x.accountType.type))
      .map((x) => x.id);

    return balanceAdjustments.filter((x) => filteredAccountsIds.includes(x.accountId));
  }, [balanceAdjustments, allAccountsTypes, accounts]);

  return (
    <Box className={clsx([classes.root, (main || !expanded) && 'shadowed'])}>
      <Box className={classes.header}>
        <Typography variant='h6'>{item.title}</Typography>
        <Box className={classes.balancesContainer}>
          {balanceRecords.map((balance) => (
            <Box className={classes.balanceRecord} key={balance.label}>
              <Typography variant='subtitle2'>{balance.label}</Typography>
              <Box
                className={clsx([
                  classes.balanceCount,
                  balance.class,
                  balance.mode === mode && 'active',
                ])}
                onClick={() => onBalanceClick(balance.mode)}
              >
                {!balance.alert ? (
                  <Typography variant='subtitle2'>{balance.amount}</Typography>
                ) : (
                  <Tooltip
                    title={`Since a Balance Sheet target total has been established, a section adjustment should be created to ensure 
                      the account totals balance with the Balance Sheet total. 
                      To achieve this, assign one or more accounts as targets for the section adjustment.`}
                  >
                    <Box className={classes.flex}>
                      <Typography variant='subtitle2'>{balance.amount}</Typography>
                      <img src={AlertIcon} alt='alert' />
                    </Box>
                  </Tooltip>
                )}
              </Box>
            </Box>
          ))}
        </Box>
        <IconButton onClick={onToggleExpanded} className={classes.expandIcon}>
          {expanded ? (
            <img alt='arrow up' src={ChevronUpIcon} />
          ) : (
            <img alt='arrow down' src={ChevronDownIcon} />
          )}
        </IconButton>
      </Box>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        {item.items?.length ? (
          <Box className={classes.itemsListContent}>
            {mode === BalancingViewMode.balanceSheet ? (
              <BalanceSheetTable type={item.balancingType} project={project} />
            ) : (
              <Box className={classes.adjustmentsDetails}>
                <CollapsibleAdjustmentsDetails
                  adjustments={[]}
                  balanceAdjustments={filteredBalanceAdjustments}
                  isBalanced={isBalanced}
                  accounts={accounts}
                  category={item.title}
                  project={project}
                  types={allAccountsTypes}
                  balancingType={item.balancingType}
                  accountsTotal={
                    item.sourceBalance +
                    item.adjustments +
                    item.sectionAdjustments -
                    item.receivedSectionAdjustment
                  }
                  balanceSheetTotal={item.totalBalance}
                />
              </Box>
            )}
            {item.items.map((item) => (
              <BalancingItem
                key={item.title}
                project={project}
                accounts={accounts}
                balanceAdjustments={balanceAdjustments}
                item={item}
                mode={mode}
                onChangeMode={onChangeMode}
                onAdjustmentsChanged={onAdjustmentsChanged}
              />
            ))}
          </Box>
        ) : (
          <Box className={classes.itemContent}>
            {mode === BalancingViewMode.accounts ? (
              <AccountsTable
                type={item.accountsType}
                balancingType={item.balancingType}
                project={project}
                accounts={accounts}
                category={item.title}
                showFees={item.showFees}
                showPremiums={item.showPremiums}
                accountsTotal={
                  item.sourceBalance +
                  item.adjustments +
                  item.sectionAdjustments -
                  item.receivedSectionAdjustment
                }
                balanceSheetTotal={item.totalBalance}
                onAdjustmentsChanged={onAdjustmentsChanged}
                isBalanced={isBalanced}
              />
            ) : (
              <BalanceSheetTable type={item.balancingType} project={project} />
            )}
          </Box>
        )}
      </Collapse>
    </Box>
  );
};
