import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { useCallback, useMemo } from 'react';

import { Box } from '@mui/material';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { IAdjustmentDefinition } from '@models/interfaces/entities/IAdjustmentDefinition';
import { formatBalance } from '../../../../utils';
import useStyles from './styles';

interface IProps {
  adjustmentDefinitions: IAdjustmentDefinition[];
  adjustments: IAdjustment[];
}

enum TierLimitNames {
  Coupon = 'coupon',
  Seasonality = 'seasonality',
  Term = 'term',
  MaturityDate = 'maturity_date',
  Balance = 'balance',
  BalanceProgressive = 'balance_progressive',
}

export const SourceBalancesTab = ({ adjustments, adjustmentDefinitions }: IProps) => {
  const { classes } = useStyles();

  const tierLimitValueFormat = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }),
    [],
  );

  const getTierLimitsLabel = useCallback(
    (
      tierLimits:
        | {
            name: string;
            lower: number;
            upper: number;
          }[]
        | null
        | undefined,
    ) => {
      if (!tierLimits || !tierLimits.length) return '';

      const result = tierLimits
        .map(({ name, lower, upper }) => {
          let suffixUnit = '';
          let prefixUnit = '';
          let displayName = '';

          switch (name) {
            case TierLimitNames.Coupon:
              displayName = 'Rate';
              suffixUnit = '%';
              lower /= 100;
              upper /= 100;
              break;
            case TierLimitNames.Seasonality:
              displayName = 'Age';
              suffixUnit = ' months';
              break;
            case TierLimitNames.Term:
              displayName = 'Term';
              suffixUnit = ' months';
              break;
            case TierLimitNames.MaturityDate:
              displayName = 'Matures in';
              suffixUnit = ' months';
              break;
            case TierLimitNames.Balance:
              displayName = 'Balance';
              prefixUnit = '$';
              break;
            case TierLimitNames.BalanceProgressive:
              displayName = 'Balance (Progressive)';
              prefixUnit = '$';
              break;
            default:
              return '';
          }

          if (lower === 0 && upper !== 0) {
            return `${displayName} < ${prefixUnit}${tierLimitValueFormat.format(
              upper,
            )}${suffixUnit}`;
          } else if (upper === 0 && lower !== 0) {
            return `${displayName} > ${prefixUnit}${tierLimitValueFormat.format(
              lower,
            )}${suffixUnit}`;
          } else if (lower !== 0 && upper !== 0) {
            return `${displayName} ${prefixUnit}${tierLimitValueFormat.format(
              lower,
            )} - ${prefixUnit}${tierLimitValueFormat.format(upper)}${suffixUnit}`;
          }

          return '';
        })
        .filter(Boolean)
        .sort((a, b) => a.localeCompare(b));

      return result.join('<br />');
    },
    [],
  );

  const columns = useMemo(
    () =>
      [
        {
          field: 'sourceSubAccountId',
          headerName: 'Type Code',
          type: 'string',
          flex: 1,
        },
        {
          field: 'tierLimits',
          headerName: 'Tier Limits',
          type: 'string',
          flex: 1,
          sortable: false,
          renderCell: (params) => (
            <Box
              dangerouslySetInnerHTML={{
                __html: getTierLimitsLabel(params.value),
              }}
            />
          ),
        },
        {
          field: 'sourceTotal',
          headerName: 'Type Code Balance',
          type: 'number',
          flex: 1,
          renderCell: (params) => (params.value !== undefined ? formatBalance(params.value) : ''),
        },
        {
          field: 'amount',
          headerName: 'Contribution to Account',
          type: 'number',
          flex: 1,
          renderCell: (params) => formatBalance(params.value || 0),
        },
      ] as GridColDef<IAdjustment>[],
    [],
  );

  const adjustmentItems = useMemo<
    (IAdjustment & {
      tierLimits: {
        name: string;
        lower: number;
        upper: number;
      }[];
    })[]
  >(
    () =>
      adjustments.map((x) => ({
        ...x,
        tierLimits:
          adjustmentDefinitions.find((ad) => ad.id === x.adjustmentDefinitionId)?.tierLimits || [],
      })),
    [adjustments, adjustmentDefinitions],
  );

  return (
    <>
      <Box className={classes.root}>
        <DataGridPremium
          rows={adjustmentItems}
          density='compact'
          columns={columns}
          className={classes.table}
          hideFooter
          initialState={{
            sorting: {
              sortModel: [{ field: 'sourceSubAccountId', sort: 'asc' }],
            },
            aggregation: {
              model: {
                amount: 'sum',
              },
            },
          }}
          getRowHeight={(params) => {
            const tierLimits =
              adjustmentItems.find((item) => item.id === params.id)?.tierLimits || [];
            return tierLimits.length > 1 ? 20 * tierLimits.length : undefined;
          }}
        />
      </Box>
    </>
  );
};
