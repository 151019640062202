import { Box, Collapse } from '@mui/material';
import { useMemo, useState } from 'react';

import { AdjustmentsTable } from '../AdjustmentsTable';
import { BalanceAdjustmentsTable } from '../BalanceAdjustmentsTable';
import ChevronDownIcon from '@assets/icons/chevron-down.svg';
import ChevronUpIcon from '@assets/icons/chevron-up.svg';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { IProject } from '@models/interfaces/entities/IProject';
import { IUpdateAdjustmentDefinitionData } from '@models/interfaces/additional/IUpdateAdjustmentDefinitionData';
import useStyles from './styles';

interface IProps {
  project: IProject;
  accounts: IAccount[];
  adjustments: IAdjustment[];
  balanceAdjustments: IAdjustment[];
  types: number[];
  balancingType: number;
  category: string;
  balanceSheetTotal: number;
  accountsTotal: number;
  isBalanced: boolean;
  withTopPadding?: boolean;
  updateAdjustmentDefinition?: (
    url: string,
    data: IUpdateAdjustmentDefinitionData,
    callback?: () => void,
  ) => void;
  deleteAdjustmentDefinition?: (url: string) => void;
}

export const CollapsibleAdjustmentsDetails = ({
  project,
  accounts,
  adjustments,
  balanceAdjustments,
  types,
  balancingType,
  category,
  balanceSheetTotal,
  accountsTotal,
  isBalanced,
  withTopPadding,
  updateAdjustmentDefinition,
  deleteAdjustmentDefinition,
}: IProps) => {
  const { classes } = useStyles();

  const [showDetails, setShowDetails] = useState(false);

  const onToggleShowDetails = () => {
    setShowDetails((prev) => !prev);
  };

  const showBalanceAdjustmentsSection = useMemo(() => {
    return !!balanceAdjustments.length || !isBalanced;
  }, [isBalanced, balanceAdjustments]);

  const showAdjustmentsSection = useMemo(() => {
    return !!adjustments.length;
  }, [adjustments]);

  if (!showAdjustmentsSection && !showBalanceAdjustmentsSection) return null;

  return (
    <>
      <Collapse in={showDetails}>
        {showBalanceAdjustmentsSection && (
          <Box className={withTopPadding ? classes.pt24 : undefined}>
            <BalanceAdjustmentsTable
              adjustments={balanceAdjustments}
              isBalanced={isBalanced}
              accounts={accounts}
              category={category}
              project={project}
              types={types}
              balancingType={balancingType}
              balanceSheetTotal={balanceSheetTotal}
              accountsTotal={accountsTotal}
            />
          </Box>
        )}

        {showAdjustmentsSection && (
          <AdjustmentsTable
            adjustments={adjustments}
            accounts={accounts}
            category={category}
            project={project}
            types={types}
            updateAdjustmentDefinition={updateAdjustmentDefinition}
            deleteAdjustmentDefinition={deleteAdjustmentDefinition}
          />
        )}
      </Collapse>
      <Box className={classes.expandButton} onClick={onToggleShowDetails}>
        {!showDetails ? (
          <img src={ChevronDownIcon} alt='chevron-down' />
        ) : (
          <img src={ChevronUpIcon} alt='chevron-up' />
        )}
      </Box>
    </>
  );
};
