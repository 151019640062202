import { BalancingViewMode, IBalancingItem } from './components/BalancingItem';
import { IBalancingNode, rootBalancingNodes } from './common/BalancingNodes';
import { getAccounts, getAdjustments, getTotalAdjustments } from '@services/api';
import { useApi, useLoader, useUpdateEffect } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { AdjustmentOperation } from './common/AdjustmentOperation';
import { BalancingItemWithTabs } from './components/BalancingItemWithTabs';
import { Box } from '@mui/material';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { ILink } from '@models/interfaces/entities/ILink';
import { IProject } from '@models/interfaces/entities/IProject';
import { ITotalAdjustment } from '@models/interfaces/entities/ITotalAdjustment';
import { Loader } from '@components/Loader';
import useStyles from './styles';

interface IProps {
  project: IProject;
}

export const BalancingTab = ({ project }: IProps) => {
  const { classes } = useStyles();

  const [accounts, setAccounts] = useState<IAccount[]>([]);
  const [viewMode, setViewMode] = useState<BalancingViewMode>(BalancingViewMode.accounts);
  const [totalAdjustments, setTotalAdjustments] = useState<ITotalAdjustment[]>([]);
  const [balanceAdjustments, setBalanceAdjustments] = useState<IAdjustment[]>([]);

  const reloadTotalAdjustments = useCallback((link: ILink) => {
    if (link) {
      getTotalAdjustmentsRequest(link.href);
    }
  }, []);

  const reloadBalanceAdjustments = useCallback((link: ILink) => {
    if (link) {
      getAdjustmentsRequest(
        link.href,
        [AdjustmentOperation.Balance],
        undefined,
        undefined,
        undefined,
        undefined,
      );
    }
  }, []);

  const {
    request: getAccountsRequest,
    data: getAccountsData,
    loading: getAccountsLoading,
  } = useApi(getAccounts, null, { handleErrors: true });

  const {
    request: getTotalAdjustmentsRequest,
    data: getTotalAdjustmentsData,
    loading: getTotalAdjustmentsLoading,
  } = useApi(getTotalAdjustments, null, { handleErrors: true });

  const {
    request: getAdjustmentsRequest,
    data: getAdjustmentsData,
    loading: getAdjustmentsLoading,
  } = useApi(getAdjustments, null, { handleErrors: true });

  useEffect(() => {
    if (project.links[Actions.getAccounts]) {
      getAccountsRequest(project.links[Actions.getAccounts].href);
    }
  }, [project.links[Actions.getAccounts]]);

  useEffect(() => {
    reloadTotalAdjustments(project.links[Actions.getTotalAdjustments]);
  }, [project.links[Actions.getTotalAdjustments]]);

  useEffect(() => {
    reloadBalanceAdjustments(project.links[Actions.getAdjustments]);
  }, [project.links[Actions.getAdjustments]]);

  useUpdateEffect(() => {
    if (getAccountsData) {
      setAccounts(getAccountsData.items);
    }
  }, [getAccountsData]);

  useUpdateEffect(() => {
    if (getTotalAdjustmentsData) {
      setTotalAdjustments(getTotalAdjustmentsData);
    }
  }, [getTotalAdjustmentsData]);

  useUpdateEffect(() => {
    if (getAdjustmentsData) {
      setBalanceAdjustments(getAdjustmentsData.items);
    }
  }, [getAdjustmentsData]);

  const onAdjustmentsChanged = useCallback(() => {
    reloadBalanceAdjustments(project.links[Actions.getAdjustments]);
    reloadTotalAdjustments(project.links[Actions.getTotalAdjustments]);
  }, [project.links[Actions.getAdjustments], project.links[Actions.getTotalAdjustments]]);

  const onChangeViewMode = useCallback((mode: BalancingViewMode) => {
    setViewMode(mode);
  }, []);

  const calculateAccountsAmounts = useCallback(
    (type: number, totalAdjustments: ITotalAdjustment[]) => {
      const sourceBalance =
        totalAdjustments.find(
          (adj) => adj.targetType === type && adj.operation === AdjustmentOperation.Initial,
        )?.amount || 0;

      const adjustments = totalAdjustments
        .filter(
          (adj) =>
            adj.targetType === type &&
            [
              AdjustmentOperation.Manual,
              AdjustmentOperation.Add,
              AdjustmentOperation.Subtract,
              AdjustmentOperation.Match,
              AdjustmentOperation.Fees,
              AdjustmentOperation.Premium,
            ].includes(adj.operation as AdjustmentOperation),
        )
        .reduce((sum, adj) => sum + adj.amount, 0);

      const sectionAdjustments =
        totalAdjustments.find(
          (adj) => adj.targetType === type && adj.operation === AdjustmentOperation.Balance,
        )?.amount || 0;

      return { sourceBalance, adjustments, sectionAdjustments };
    },
    [],
  );

  const calculateSectionAdjustmentsTotal = useCallback(
    (
      accountsType: number,
      balancingType: number,
      balanceAdjustments: IAdjustment[],
      accounts: IAccount[],
    ) => {
      const balancingAccountIds = accounts
        .filter((x) => x.accountType.type === balancingType)
        .map((x) => x.id);
      const sectionTargetAccountIds = accounts
        .filter((x) => x.accountType.type === accountsType)
        .map((x) => x.id);

      return {
        ownedSectionAdjustment: balanceAdjustments
          .filter((adj) => adj.sourceAccountId && balancingAccountIds.includes(adj.sourceAccountId))
          .reduce((sum, adj) => sum + adj.amount, 0),
        receivedSectionAdjustment: balanceAdjustments
          .filter(
            (adj) =>
              adj.sourceAccountId &&
              !balancingAccountIds.includes(adj.sourceAccountId) &&
              sectionTargetAccountIds.includes(adj.accountId),
          )
          .reduce((sum, adj) => sum + adj.amount, 0),
      };
    },
    [],
  );

  const calculateBalanceSheetTotal = useCallback(
    (type: number, totalAdjustments: ITotalAdjustment[]) => {
      const balanceSheetItems = totalAdjustments.filter(
        (adj) =>
          adj.targetType === type &&
          [AdjustmentOperation.AddBalance, AdjustmentOperation.ReverseBalance].includes(
            adj.operation as AdjustmentOperation,
          ),
      );
      return {
        total: balanceSheetItems.reduce((sum, adj) => sum + adj.amount, 0),
        count: balanceSheetItems.length,
      };
    },
    [],
  );

  const convertToBalancingItem = useCallback(
    (
      node: IBalancingNode,
      totalAdjustments: ITotalAdjustment[],
      balanceAdjustments: IAdjustment[],
      accounts: IAccount[],
    ): IBalancingItem => {
      if (node.accountsType === -1 && node.items && node.items.length > 0) {
        const childItems = node.items.map((childNode: IBalancingNode) =>
          convertToBalancingItem(childNode, totalAdjustments, balanceAdjustments, accounts),
        );

        const sourceBalance = childItems.reduce((sum, child) => sum + child.sourceBalance, 0);
        const adjustments = childItems.reduce((sum, child) => sum + child.adjustments, 0);
        const aggregatedTotalBalance = childItems.reduce(
          (sum, child) => sum + child.totalBalance,
          0,
        );
        const aggregatedSectionAdjustments = childItems.reduce(
          (sum, child) => sum + child.sectionAdjustments,
          0,
        );
        const { ownedSectionAdjustment, receivedSectionAdjustment } =
          calculateSectionAdjustmentsTotal(
            node.accountsType,
            node.balancingType,
            balanceAdjustments,
            accounts,
          );
        const { total: totalBalance, count } = calculateBalanceSheetTotal(
          node.balancingType,
          totalAdjustments,
        );

        return {
          title: node.title,
          accountsType: node.accountsType,
          balancingType: node.balancingType,
          sourceBalance,
          adjustments,
          sectionAdjustments: aggregatedSectionAdjustments,
          ownedSectionAdjustment,
          receivedSectionAdjustment,
          totalBalance: count ? totalBalance : aggregatedTotalBalance,
          hideSource: node.hideSource,
          showFees: node.showFees,
          showPremiums: node.showPremiums,
          items: childItems,
        };
      } else {
        const { sourceBalance, adjustments, sectionAdjustments } = calculateAccountsAmounts(
          node.accountsType,
          totalAdjustments,
        );
        const { ownedSectionAdjustment, receivedSectionAdjustment } =
          calculateSectionAdjustmentsTotal(
            node.accountsType,
            node.balancingType,
            balanceAdjustments,
            accounts,
          );
        const { total: totalBalance } = calculateBalanceSheetTotal(
          node.balancingType,
          totalAdjustments,
        );

        return {
          title: node.title,
          accountsType: node.accountsType,
          balancingType: node.balancingType,
          sourceBalance,
          adjustments,
          sectionAdjustments,
          ownedSectionAdjustment,
          receivedSectionAdjustment,
          totalBalance,
          hideSource: node.hideSource,
          showFees: node.showFees,
          showPremiums: node.showPremiums,
          items: node.items
            ? node.items.map((childNode: IBalancingNode) =>
                convertToBalancingItem(childNode, totalAdjustments, balanceAdjustments, accounts),
              )
            : undefined,
        };
      }
    },
    [calculateAccountsAmounts, calculateBalanceSheetTotal],
  );

  const assetsItem = useMemo(
    () =>
      convertToBalancingItem(rootBalancingNodes[0], totalAdjustments, balanceAdjustments, accounts),
    [rootBalancingNodes, totalAdjustments, balanceAdjustments, accounts, convertToBalancingItem],
  );
  const liabilitiesAndNetWorthItem = useMemo(
    () =>
      convertToBalancingItem(rootBalancingNodes[1], totalAdjustments, balanceAdjustments, accounts),
    [rootBalancingNodes, totalAdjustments, balanceAdjustments, accounts, convertToBalancingItem],
  );

  const showLoader = useLoader(
    getAccountsLoading,
    getTotalAdjustmentsLoading,
    getAdjustmentsLoading,
  );

  return (
    <Box className={classes.root}>
      <BalancingItemWithTabs
        project={project}
        item={assetsItem}
        accounts={accounts}
        balanceAdjustments={balanceAdjustments}
        mode={viewMode}
        onChangeMode={onChangeViewMode}
        onAdjustmentsChanged={onAdjustmentsChanged}
      />

      <BalancingItemWithTabs
        project={project}
        item={liabilitiesAndNetWorthItem}
        accounts={accounts}
        balanceAdjustments={balanceAdjustments}
        mode={viewMode}
        onChangeMode={onChangeViewMode}
        onAdjustmentsChanged={onAdjustmentsChanged}
      />
      <Loader show={showLoader} />
    </Box>
  );
};
