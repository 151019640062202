import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    paddingTop: 24,
  },
  table: {
    backgroundColor: t.palette.custom.white[100],

    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600,
    },
  },
  limitedHeightTable: {
    maxHeight: 480,
  },
}));

export default useStyles;
