import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    paddingTop: 40,
    paddingLeft: 24,
    paddingRight: 24,
    position: 'relative',

    '&.lp-0': {
      paddingLeft: 0,
    },

    '&.rp-0': {
      paddingRight: 0,
    },
  },
  tab: {
    backgroundColor: t.palette.custom.white[100],
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 2,
    paddingTop: 8,
    paddingRight: 15,
    paddingLeft: 15,
    width: 230,
    borderRadius: 20,
    textAlign: 'center',
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: 0,

    '&.left': {
      left: 0,
    },
    '&.right': {
      right: 0,
    },
    '&.green': {
      backgroundColor: t.palette.custom.success[50],
    },
    '&.blue': {
      backgroundColor: t.palette.custom.info[50],
    },
    '&.active': {
      borderColor: t.palette.custom.dark[100],
    },
  },
}));

export default useStyles;
